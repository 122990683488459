<template>
  <div class="flex flex-col items-center w-screen h-screen relative pl-18">
    <Header />
    <div class="h-full w-full shadow-lg flex items-start justify-center">
      <div class="relative flex-grow p-10 pb-32 loginContainer">
        <Auth />
        <div class="flex-grow"></div>
      </div>
      <img
        src="../../assets/images/landing/right.svg"
        alt=""
        class="absolute bottom-0 left-1/2 transform -translate-x-1/2"
      />
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { getConfig } from '@/services/api/config'
import Auth from '@/components/auth/Auth'
import Header from '@/components/Drive/core/Header.vue'
export default {
  name: 'LandingPage',
  data: function() {
    return {
      landingPageURL: ''
    }
  },
  components: {
    Auth,
    Header
  },
  computed: {
    ...mapGetters({
      isMobile: 'app/isMobile'
    })
  },
  methods: {
    async getConfig() {
      let config = await getConfig()
      let ref = localStorage['ref']
      let show, coupon
      if (config && config['coupons'] && config['coupons'][ref]) {
        this.bannerText = config['coupons'][ref]['msg']
        coupon = config['coupons'][ref]['coupon']
        show = config['coupons'][ref]['show']
      } else {
        this.bannerText =
          config &&
          config['coupons'] &&
          config['coupons']['default'] &&
          config['coupons']['default']['msg']
            ? config['coupons']['default']['msg']
            : ''
        show =
          config &&
          config['coupons'] &&
          config['coupons']['default'] &&
          config['coupons']['default']['show']
      }
      if (show) {
        this.showBanner = true
        localStorage['coupon'] = coupon
      }
    }
  },
  mounted() {
    window.Intercom('update', {
      hide_default_launcher: false
    })
    delete localStorage['retryCount']
    if (
      localStorage['invite'] ||
      (localStorage['teamInvite'] && localStorage['invitedEmail'])
    ) {
      this.openLogin()
    }
    this.getConfig()
  },
  created() {
    let url = window.location.origin.split('app.')
    if (url && url.length >= 2) {
      this.landingPageURL = 'https://' + url[1]
    }
  }
}
</script>

<style lang="scss" scoped>
.loginContainer {
  width: 400px;
  max-width: 100%;
}
</style>
